<template>



  <GeneralSearchTags  
    :class="{filters: true}"
    :tags="tags" 
    :tagsselected="tagsSelected"
    :tagsdefault="tags" 
    :tagscount="giveawaysData?.tags_list"      
    :tag-as-link="true"
    :tag-link="'/giveaways'"
    :modify-path="false"
    @choose="chooseTags"
    @unchoose="unchooseTags"
  />

  <div class="completed" v-if="giveawaysData">

    <GiveawayRow 
      v-for="(data, item_key) in giveawaysData.items_giveaway" 
      :key="item_key" 
      :data="data"
    />
    
  </div>

  <ClientOnly>        
    <UiInfinityScroll 
      v-if="giveawaysData?.items_giveaway?.length >= limit"
      :is-loading="giveawaysDataStatus === 'pending'"
      ref="elInfinityScroll"
    />
  </ClientOnly>
 
</template>


<script setup lang="ts">

import type { TagsSelected } from '@/types/other'
import { TagsGiveaway, getTagsSimple } from '@/types/other'

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const route = useRoute()
const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const { $api } = useNuxtApp()
const elInfinityScroll = ref(null)
const tags = ref(structuredClone(TagsGiveaway))
const tagsSelected = ref<TagsSelected>(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))


const clearPagination = function(){     
  start.value = 0;  
}

const unchooseTags = function(selected: string){  
  tagsSelected.value = tagsSelected.value.filter((item: string) => item != selected);
}

const chooseTags = function(selected: string){          
  tagsSelected.value.push(selected);
  clearPagination();    
}



const loadDataCompleted = async function() {     

  let tmp_tags = getTagsSimple(tags.value, tagsSelected.value);
  tmp_tags = tmp_tags ? 'completed,' + tmp_tags : 'completed';

  console.log('tmp_tags');
  console.log(tmp_tags);

  return await $api.getGiveaways(
    start.value,
    limit.value,
    tmp_tags
  )  
}



const { status: giveawaysDataStatus,  data: giveawaysData } = await useLazyAsyncData('giveawaysData', loadDataCompleted)


watchArray([start, tagsSelected], async ([new_start, new_tagsSelected], added, removed) => {
  console.log('watcher');
  giveawaysDataStatus.value = 'pending'
  var dt = await loadDataCompleted();

  if(new_start === 0){
    giveawaysData.value.items_giveaway = [];
    isEnded.value = false;
  }
    
  if(dt?.items_giveaway?.length == 0)
    isEnded.value = true;  

  if(dt.tags_list?.length)
  giveawaysData.value.tags_list = dt.tags_list;

  giveawaysData.value.items_giveaway = [...giveawaysData.value.items_giveaway, ...dt.items_giveaway]
  giveawaysDataStatus.value = 'success'
}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(giveawaysDataStatus.value === 'success' && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

 


</script>









<style scoped>

.completed{
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1.5rem;
  margin-top: 1rem;
}


@container pb (max-width: 600px) {
  .running{
    flex-direction: column;
  }
}


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.header_row{
  margin-bottom: 1rem;
}

.mansory{
  column-gap: 3%;  
  column-count: 2;
}
.mansory > *{
  width: 100%;
  display: inline-block;
}
</style>
